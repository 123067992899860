<script>
	import { page } from '$app/stores';
	import Button from '$lib/Components/Layout/Button/Button.svelte';
	import Image from '$lib/Components/Layout/Image/Image.svelte';

	import imgSearch from './images/Magnifying_glass_icon.svg';
	import { links } from './links';
	import Logo from './Logo.svelte';

	export let showMobileEstimateButton = true;
	export let showSearch = false;

	let menu;

	let closeMenu = () => {
		menu.checked = false;
	};
</script>

<header
	class:fixed={$page?.data?.headerPosition != 'relative'}
	class="content-grid full-width top-0 isolate z-50 grid w-full grid-rows-[auto_1fr]
	place-items-center bg-[var(--indow-blue)] shadow-md md:relative md:bg-white [&_*]:min-w-0"
>
	<div
		class="banner full-width grid w-full place-items-center justify-self-start bg-[var(--indow-blue)] py-4 text-white"
	>
		<div
			class="grid w-full grid-cols-[1fr_auto] justify-end
			"
		>
			<p
				class="m-0 h-4 !place-self-end !text-left text-sm font-medium text-white md:h-auto md:text-right"
			>
				Save up to $1,200 on thermal window inserts.
				<a
					href="/window-insert-tax-savings"
					class="!mr-0 ml-4 !gap-4 !p-0 font-bold !capitalize italic text-white !underline"
					>Learn More</a
				>
			</p>
		</div>
	</div>

	<div class="nav-container mx-auto grid w-full grid-cols-1 items-center">
		<nav
			class="relative grid w-full grid-cols-[auto_auto] text-xl leading-4
			md:grid-cols-[auto_1fr_auto] md:grid-rows-1 md:items-center md:gap-4"
			class:md:grid-cols-[auto_1fr_auto_auto]={showSearch}
		>
			<Logo />
			<input
				class="menu-btn peer hidden"
				type="checkbox"
				id="menu-btn"
				bind:this={menu}
			/>
			<label
				class="menu-icon relative inline-block cursor-pointer select-none justify-end justify-self-end px-4 py-7 md:hidden"
				for="menu-btn"
				><span
					class="navicon relative block h-0.5 w-6 bg-white before:absolute before:top-2 before:block
					before:h-full before:w-full before:bg-white before:transition-transform before:duration-200
					before:ease-out before:content-[''] after:absolute after:-top-2 after:block after:h-full
					after:w-full after:bg-white after:transition-transform after:duration-200 after:ease-out
					after:content-['']"
				/></label
			>
			<div
				class="grid grid-rows-[0fr] transition-[grid-template-rows] duration-300 ease-in-out peer-checked:grid-rows-[1fr] max-md:col-span-full"
			>
				<div class="overflow-hidden md:overflow-visible">
					<ul
						class=" menu justify-inherit col-start-1 col-end-3 row-start-2 mb-0 flex max-h-[85dvh] w-full
				 list-none flex-col overflow-y-auto overflow-x-hidden
				py-8 uppercase ease-out
				md:relative md:col-start-2 md:row-start-1 md:m-0 md:h-36 md:min-h-36 md:list-none md:flex-row
				md:items-center md:justify-center md:gap-6 md:overflow-visible md:p-0 [&_li]:mb-0"
						class:topButton={showMobileEstimateButton}
					>
						{#each links as link}
							<li
								class="relative grid grid-cols-[1fr_auto] text-left outline outline-1
								outline-transparent
							transition-[outline] duration-500 max-md:items-center max-md:hover:outline-1
							max-md:hover:outline-indow-white md:h-full md:place-items-center md:text-center
							md:before:absolute md:before:top-0 md:before:h-[3px] md:before:w-[80%] md:before:bg-indow-blue
							md:before:opacity-0 md:before:transition md:before:duration-500 md:before:content-[''] md:focus-within:text-indow-blue"
								class:md:hidden={link.hideDesktop}
								class:active={$page.url.pathname === link.href}
							>
								<a
									class="grid h-fit grid-cols-[1.5rem_1fr] items-center gap-4 hyphens-none
							py-4 uppercase text-indow-white no-underline transition-colors duration-500 md:flex
							md:h-full md:w-full md:items-center md:justify-center md:p-0 md:text-[0.8rem]
							md:font-bold md:text-indow-grey-dark md:hover:text-indow-blue md:focus:text-indow-blue"
									href={link.href}
									on:click={closeMenu}
								>
									<div class="inline md:hidden">
										<Image src={link.img} />
									</div>
									{link.name}
								</a>
								{#if link.children}
									<input
										class="menu-btn peer hidden h-full w-full"
										type="checkbox"
										id={link.name}
									/>
									<label
										class="menu-icon translate-x h-fit w-full cursor-pointer p-4 text-white transition-transform
										duration-500 peer-checked:rotate-180 md:hidden"
										for={link.name}>▼</label
									>
									<div
										class="grid grid-rows-[0fr] transition-[grid-template-rows] duration-300 ease-in-out peer-checked:grid-rows-[1fr] max-md:col-span-full"
									>
										<div class="overflow-hidden md:overflow-visible">
											<ul
												class="menu justify-inherit duration-200s md:duration-200s col-start-1 col-end-3
								row-start-2 flex w-full list-none flex-col gap-4 overflow-hidden
								overflow-x-hidden pb-4 uppercase transition-[max-height]
								ease-out peer-checked:pb-4 peer-checked:pt-4 md:pointer-events-none md:absolute
								md:-left-8 md:top-0 md:col-start-1 md:grid md:w-max
								md:flex-col md:place-content-start md:gap-0 md:overflow-visible
								md:bg-indow-white md:opacity-0 md:shadow-md md:transition md:duration-500 md:peer-checked:pb-0"
												aria-label="submenu"
											>
												{#each link.children as child}
													<li
														class="md:first:border-t-solid
						relative grid pl-0 outline outline-1 outline-transparent transition-[outline] duration-500
										max-md:hover:outline-1 max-md:hover:outline-indow-white
										md:h-full md:w-full md:place-content-start md:place-items-start md:whitespace-nowrap md:bg-indow-white
						md:p-0 md:first:border-t-2
										md:first:border-t-indow-blue md:last:pb-4 md:focus-within:bg-indow-white md:hover:bg-indow-white"
														class:active={$page.url.pathname === child.href}
													>
														<a
															class="hyphens-none uppercase leading-normal text-indow-white
											no-underline transition-colors duration-500 md:flex md:h-full md:w-full
											md:items-center md:justify-start md:p-0 md:px-8 md:py-4 md:text-[0.8rem]
											md:font-bold md:text-indow-grey-dark md:hover:text-indow-blue
											md:focus:text-indow-blue"
															href={child.href}
															on:click={closeMenu}
														>
															<div class="md:hidden">
																{#if child.img}
																	<Image src={child.img} />
																{/if}
															</div>
															{child.name}
														</a>
													</li>
												{/each}
											</ul>
										</div>
									</div>
								{/if}
							</li>
						{/each}
						{#if showSearch}
							<!-- TODO: connect this form to a real search page/component -->
							<li class="md:grid-col-auto col-span-2 md:hidden">
								<form
									action="https://indowwindows.com"
									role="search"
									class="w-inherit m-0 flex border-indow-grey-light px-2 py-0 md:rounded-br-lg
							md:rounded-tr-lg md:border-indow-white md:bg-indow-white"
								>
									<input
										type="search"
										title="Search"
										name="s"
										placeholder="Search"
										class="w-full cursor-pointer overflow-hidden rounded-br-none rounded-tr-none
								border-transparent px-4 py-0 md:max-w-0 md:p-0"
										required
									/>
									<button
										><Image
											width="20"
											height="20"
											alt="Search"
											class="m-2 h-4 w-auto rounded-br-md rounded-tr-md border-2 border-l-0 
									border-indow-grey-light bg-indow-grey-light "
											src={imgSearch}
										/></button
									>
								</form>
							</li>
						{/if}
					</ul>
				</div>
			</div>
			{#if showMobileEstimateButton && !$page?.data?.hideHeaderButton}
				<div
					class="absolute left-0 top-0 w-full translate-y-[150%] md:static md:bottom-auto
					md:flex md:w-auto md:translate-y-0"
				>
					<Button
						mobileHeight="thin"
						href={'/indow-cost'}
						color="yellow"
						border="white"
					>
						Get <span class="inline max-md:hidden">A Free </span>Estimate
					</Button>
				</div>
			{/if}
			{#if showSearch}
				<div class="max-md:hidden">
					<a class="search w-max" href="/search">
						<img
							class="search h-4"
							width="20"
							height="20"
							alt="Search"
							src={imgSearch}
						/>
					</a>
				</div>
			{/if}
		</nav>
	</div>
</header>

<style>
	.menu-btn:checked ~ .menu-icon .navicon {
		background: transparent;
	}

	.menu-btn:checked ~ .menu-icon .navicon:before {
		transform: rotate(-45deg);
	}

	.menu-btn:checked ~ .menu-icon .navicon:after {
		transform: rotate(45deg);
	}

	.menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
	.menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
		top: 0;
	}

	@media (min-width: 768px) {
		li.active > a,
		li:has(.active) > a {
			color: var(--indow-blue);
		}

		li:not(.active):hover::before,
		li.active::before,
		li:has(.active)::before {
			opacity: 1;
		}

		li:not(.active):focus-within::before,
		li.active:focus-within::before {
			opacity: 1;
		}

		ul.menu li:hover ul {
			opacity: 1;
			pointer-events: initial;
		}
		ul.menu li:focus-within ul {
			opacity: 1;
			pointer-events: initial;
		}
	}
</style>
